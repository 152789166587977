import React, { cloneElement } from 'react';
import { useInView } from 'react-intersection-observer';

import Head from 'next/head';
import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import VwoTarget from '@components/preview/VwoTarget';
import DeskoptBr from '@components/ui/DeskoptBr';
import Underline from '@components/ui/Underline';
import { CTAStartFreeCreateAccount } from '@components/ui/design2023/CTAStartFreeCreateAccount';

import useTranslation from '@hooks/useTranslation';

import styles from './Hero.module.css';
import Hero1xAVIF from './assets/hero@1x.avif';
import Hero1xPNG from './assets/hero@1x.png';
import Hero2xAVIF from './assets/hero@2x.avif';
import Hero2xPNG from './assets/hero@2x.png';

type ContentProps = Omit<HeroProps, 'container'>;

const Content = ({ customStyles }: ContentProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    fetchpriority="high" // eslint-disable-line
                    href={Hero1xPNG.src}
                    as="image"
                    type="image/avif"
                    imageSrcSet={`${Hero1xAVIF.src} 1x, ${Hero2xAVIF.src} 2x`}
                    imageSizes={`(min-width: ${Hero1xAVIF.width}px) ${Hero1xAVIF.width}px, 100vw`}
                />
            </Head>
            <VwoTarget id="vwo-target-homepage-preheader">
                <h1 className={styles.preheader}>{t('preheader')}</h1>
            </VwoTarget>

            <VwoTarget
                id="vwo-target-homepage-heading"
                components={[
                    {
                        name: 'u',
                        component: <Underline>Placeholder</Underline>,
                    },
                    { name: 'br', component: <DeskoptBr /> },
                    {
                        name: 'blue',
                        component: <span>Placeholder</span>,
                    },
                ]}
            >
                <p
                    className={[styles.header, customStyles?.header ?? null]
                        .filter(Boolean)
                        .join(' ')}
                >
                    <Trans
                        i18nKey="header_v_b"
                        components={{
                            u: <Underline />,
                            br: <br />,
                            span: <span />,
                        }}
                        ns="pages/homepage/2024/v1/index"
                    />
                </p>
            </VwoTarget>
            <VwoTarget id="vwo-target-homepage-subheading">
                <p
                    className={[styles.text, customStyles?.subheader ?? null]
                        .filter(Boolean)
                        .join(' ')}
                >
                    {t('text')}
                </p>
            </VwoTarget>
            <div className={styles.buttonWithText}>
                <CTAStartFreeCreateAccount
                    label={t('button_v_b')}
                    color="yellow"
                    size="large"
                />
                <small
                    dangerouslySetInnerHTML={{
                        __html: t('subbutton_v_b'),
                    }}
                />
            </div>
            <figure className={styles.picture}>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${Hero1xAVIF.src} 1x, ${Hero2xAVIF.src} 2x`}
                    />
                    <source
                        srcSet={`${Hero1xPNG.src} 1x, ${Hero2xPNG.src} 2x`}
                    />
                    <img
                        width={Hero1xPNG.width}
                        height={Hero1xPNG.height}
                        decoding="async"
                        alt=""
                        src={Hero1xPNG.src}
                    />
                </picture>
            </figure>
        </>
    );
};

interface HeroProps {
    container: React.ReactElement;
    customStyles?: {
        hero?: string;
        header?: string;
        subheader?: string;
    };
}

export default function Hero({
    container,
    customStyles,
    ...otherProps
}: HeroProps): JSX.Element {
    return (
        <section
            className={[styles.hero, customStyles?.hero]
                .filter(Boolean)
                .join(' ')}
        >
            {cloneElement(container, {
                children: (
                    <Content
                        customStyles={customStyles}
                        {...otherProps}
                    />
                ),
            })}
        </section>
    );
}
